import { ref, reactive } from "vue";
import { DealerOverviewData, DealerTableData, DealerFailReasonData, DealerStatusData }
  from "@/views/RetailValidation/Dealer/types";
import * as API from "@/API/rv/rvDealer";
import setColumnWidth from "@/utils/rv/setColumnWidth"
import getMonthLast from "@/utils/rv/getMonthLast"
import cleanEmptyEleArray from "@/utils/rv/cleanEmptyElement"
import { resetParam, resetPatrial } from '@/utils/rv/configParam'
import downloadFile from '@/utils/rv/downloadFile';
import { s3Download } from "@/utils/s3Download";
import base64toBlob from "@/utils/rv/base64toBlob"
import { useStore } from "vuex";
import useWait from '@/utils/payment/useWait';

const useDealerOverview = () => {
  const store = useStore()
  const waitBrandDataLoaded = useWait();
  // 打开筛选条件
  const isOpen = ref<boolean>(false)
  // 筛选面板
  const activeKey = ref<string>("")
  // 校验列表
  const tableData = ref<DealerTableData[]>([])
  const total = ref<number>(0)
  // 失败原因列表
  const failData = ref<DealerFailReasonData[]>([])
  // 是否显示失败原因面板
  const showFailFlag = ref<boolean>(false)
  // 状态下拉框
  const approvalStatusArray = ref<DealerStatusData[]>([])
  // 激励适用性
  const rebateFlagArray = ref<DealerStatusData[]>([])
  // 品牌
  let allBrands: string[] = []
  // 批量查询
  const batchQuery = reactive({
    batchQueryTitle: '',
    batchQueryModal: false,
    batchQueryListTitle: ''
  })
  const state = reactive<any>({
    selectedRowKeys: [], // Check here to configure the default column
    selectedRows: []
  });
  const columns = reactive([
    { title: "车架号", dataIndex: "vinNo", width: 168 },
    { title: "车型", dataIndex: "model", width: 60 },
    { title: "品牌/车型", dataIndex: "brand", width: 80 },
    { title: "年款", dataIndex: "modelYear", width: 100 },
    { title: "客户名称", dataIndex: "customerName", width: 100 },
    { title: "零售类型", dataIndex: "usage", width: 110 },
    { title: "零售日期", dataIndex: "retailDate", width: 90 },
    { title: "特殊车辆用途", dataIndex: "specialUsage", width: 116 },
    { title: "提交状态", dataIndex: "approlStatus", width: 90, slots: { customRender: "approlStatus" } },
    { title: "激励适用性", dataIndex: "checkStatus", width: 104, slots: { customRender: "checkStatus" } },
    { title: "失败原因", fixed: 'right', width: 90, slots: { customRender: "fail" } },
    { title: "文件", fixed: 'right', width: 200, slots: { customRender: "file" } },
    { title: "操作", fixed: 'right', width: 150, slots: { customRender: "action" } }
  ])
  // 获取dealerCode
  let dealerCode = store.state.user.organization.entityCode
  // 收起按钮显示位置 false 在折叠面板的header
  const showCollapsed = ref<boolean>(false)
  // 打开筛选条件面板
  const isOpenPanle = ref<boolean>(false)
  // 概览数据
  const overview = ref<DealerOverviewData>({
    currRetailMonth: "",
    currRetailSubmitDate: "",
    currRetailTotal: "",
    currUnSubmitTotal: "",
    currUsabilityRebateUnPassTotal: "",
    currVinCountOfReturn: "",
    lastRetailMonth: "",
    lastRetailSubmitDate: "",
    lastRetailTotal: "",
    lastUnSubmitTotal: "",
    lastUsabilityRebateUnPassTotal: "",
    lastVinCountOfReturn: "",
    abnormalRetailTotal: "",
    abnormalUnSubmitTotal: "",
    abnormalUsabilityRebateUnPassTotal: "",
    abnormalVinCountOfReturn: ""
  });
  // 发票/行驶证 查看弹窗
  const pictureModal = reactive({
    pictureModalFlag: false,
    pictureModalTitle: "",
    pictureModalImg: "",
    url: "",
    pdf: "",
    dealerCode: ""
  });
  // 多维查询下拉框备选项
  const selectData = reactive({
    powerTrainArray: [],
    modelYearArray: [],
    typeClassArray: []
  })
  // 筛选条件
  const queryParams = reactive({
    vinNo: "",
    model: "",
    retailStartDate: "",
    retailEndDate: "",
    usage: "",
    commissionNo: "",
    typeClass: "",
    modelYear: "",
    entity: "",
    specialUsage: "",
    powerTrain: "",
    invoiceStartDate: "",
    invoiceEndDate: "",
    licenseStartDate: "",
    licenseEndDate: "",
    approlStatus: "",
    brand: "",
    brandList: [] as string[],
    checkStatus: "",
    commissionNos: [''],
    vinNos: [''],
    current: 1,
    size: 20,
    // 点击页面上的统计区域的标志
    statisticsClicked: 0,
    // 点击页面上的异常统计区域的标志
    abnormalAreaClicked: 0,
  });
  const getOverview = async () => {
    await waitBrandDataLoaded
    API.getDealerOverview(allBrands).then((res) => {
      overview.value = res;
    });
    API.getApprovalStatus().then(res => {
      approvalStatusArray.value = res
      approvalStatusArray.value.push(
        { code: "全部", msg: "全部" }, // '全部' 根据story添加一个状态
        { code: "0", msg: "" }) // '0' 是!=已经提交状态，根据story添加一个状态
    })
    API.getCheckStatus().then(res => {
      rebateFlagArray.value = res
      // '全部' 根据story添加一个状态
      rebateFlagArray.value.push({ code: "全部", msg: "全部" })
    })
    API.getSelectData().then((res: any) => {
      const newModelYearArray: any = [];
      const newpowerTrainArray: any = [];
      const newtypeClassArray: any = [];
      res.modelYear.forEach((item: any) => {
        if (item.msg !== "" && item.code !== "") {
          newModelYearArray.push(item);
          selectData.modelYearArray = newModelYearArray;
        }
      })
      res.powerTrain.forEach((item: any) => {
        if (item.msg !== "" && item.code !== "") {
          newpowerTrainArray.push(item);
          selectData.powerTrainArray = newpowerTrainArray;
        }
      })
      res.typeClass.forEach((item: any) => {
        if (item.msg !== "" && item.code !== "") {
          newtypeClassArray.push(item);
          selectData.typeClassArray = newtypeClassArray;
        }
      })
    })
  }
  const resetEvent = () => {
    resetPatrial(queryParams)
    queryParams.commissionNos = [''],
      queryParams.vinNos = [''],
      queryParams.current = 1,
      queryParams.size = 20
  };
  const getTableData = async () => {
    await waitBrandDataLoaded
    queryParams.vinNo = queryParams.vinNo.trim()
    queryParams.commissionNo = queryParams.commissionNo.trim()
    queryParams.model = queryParams.model.trim()
    queryParams.vinNos = cleanEmptyEleArray(queryParams.vinNos)
    queryParams.commissionNos = cleanEmptyEleArray(queryParams.commissionNos)

    API.getRVTable({
        ...queryParams,
        specialUsage: queryParams.specialUsage === '全部' ? '' : queryParams.specialUsage,
        brandList: queryParams.brand ? [] : allBrands
      }).then((res) => {
      tableData.value = res.content;
      total.value = res.totalElements;
      queryParams.size = res.size
      const customerColumn = ['model', 'modelYear', 'customerName', 'specialUsage', 'brand', 'retailDate']
      tableData.value.length && Object.assign(columns, setColumnWidth(columns, tableData.value, customerColumn))
      // 清除单选框
      state.selectedRowKeys = [];
      state.selectedRows = [];
    });
  }
  const dateChangeEvent = (date: string[], arg: string) => {
    switch (arg) {
      case 'retail':
        queryParams.retailStartDate = date[0]
        queryParams.retailEndDate = date[1]
        break
      case 'invoice':
        queryParams.invoiceStartDate = date[0]
        queryParams.invoiceEndDate = date[1]
        break
      case 'license':
        queryParams.licenseStartDate = date[0]
        queryParams.licenseEndDate = date[1]
        break
    }
  }
  const downloadImg = async () => {
    const vinNo = pictureModal.pictureModalTitle.substr(3)
    const fileName = pictureModal.pictureModalTitle
    await s3Download({
      url: `${pictureModal.url}?vinNo=${vinNo}&fileName=${fileName}&dealerCode=${dealerCode}`,
      method: 'get'
    })
  }

  const resetSearchParams = () => {
    resetParam(queryParams)
    queryParams.current = 1
    isOpenPanle.value = false
    isOpen.value = false
    activeKey.value = ""
  }
  
  const mulSearchEvent = () => {
    resetSearchParams()
    // 重置点击页面上的统计区域的标志
    queryParams.statisticsClicked = 0
    queryParams.abnormalAreaClicked = 0;
    getTableData()
  }
  const overviewSerachEvent = (str: string) => {
    isOpenPanle.value = false
    isOpen.value = false
    activeKey.value = ""
    resetEvent()
    // 点击了页面上的统计区域：1， 未点击统计区域：0
    queryParams.statisticsClicked = 1
    queryParams.abnormalAreaClicked = 0;
    switch (str) {
      case "leftRetialTotal":
        queryParams.retailStartDate = overview.value.currRetailMonth + "/01";
        break;
      case "leftNotSubmit":
        queryParams.retailStartDate = overview.value.currRetailMonth + "/01";
        queryParams.approlStatus = "0";
        break;
      case "leftcheckStatus":
        queryParams.retailStartDate = overview.value.currRetailMonth + "/01";
        queryParams.checkStatus = "3333";
        break;
      case "leftCheckStatusReturnedCar":
        // 退车按未点击统计区域统计（点击了页面上的统计区域不统计退车数据）
        queryParams.statisticsClicked = 0
        queryParams.retailStartDate = overview.value.currRetailMonth + "/01";
        queryParams.checkStatus = "4444";
        break;
      case "rightRetialTotal":
        queryParams.retailStartDate = overview.value.lastRetailMonth + "/01";
        break;
      case "rightNotSubmit":
        queryParams.retailStartDate = overview.value.lastRetailMonth + "/01";
        queryParams.approlStatus = "0";
        break;
      case "rightcheckStatus":
        queryParams.retailStartDate = overview.value.lastRetailMonth + "/01";
        queryParams.checkStatus = "3333";
        break;
      case "rightCheckStatusReturnedCar":
        // 退车按未点击统计区域统计（点击了页面上的统计区域不统计退车数据）
        queryParams.statisticsClicked = 0
        queryParams.retailStartDate = overview.value.lastRetailMonth + "/01";
        queryParams.checkStatus = "4444";
        break;
      case "abnormalRetialTotal":
        queryParams.retailStartDate = "";
        queryParams.abnormalAreaClicked = 1;
        break;
      case "abnormalNotSubmit":
        queryParams.retailStartDate = "";
        queryParams.approlStatus = "0";
        queryParams.abnormalAreaClicked = 1;
        break;
      case "abnormalcheckStatus":
        queryParams.retailStartDate = "";
        queryParams.checkStatus = "3333";
        queryParams.abnormalAreaClicked = 1;
        break;
      case "abnormalCheckStatusReturnedCar":
        // 退车按未点击统计区域统计（点击了页面上的统计区域不统计退车数据）
        queryParams.statisticsClicked = 0
        queryParams.retailStartDate = "";
        queryParams.checkStatus = "4444";
        queryParams.abnormalAreaClicked = 1;
        break;
    }
    queryParams.retailEndDate = queryParams.retailStartDate ? getMonthLast(queryParams.retailStartDate) : ""
    resetSearchParams()
    getTableData()
  }
  const licenseEvent = (vinNo: string, saleDealerCode = '') => {
    dealerCode = saleDealerCode ? saleDealerCode : dealerCode
    const params = { vinNo, fileName: "RE_" + vinNo, dealerCode }
    API.getlicenseView({ params }).then((res: any) => {
      pictureModal.pictureModalFlag = true
      pictureModal.pictureModalTitle = "RE_" + vinNo
      pictureModal.pictureModalImg = res.downloadUrl
      pictureModal.dealerCode = saleDealerCode
      if (res.docType.toUpperCase() === 'PDF') {
        pictureModal.pdf = URL.createObjectURL(base64toBlob(res.base64, "application/pdf"))
      } else {
        pictureModal.pdf = ''
      }
      pictureModal.url = '/rvapi/basic/certificate/licenseView'
    })
  }
  const invoiceEvent = (vinNo: string, saleDealerCode = '') => {
    dealerCode = saleDealerCode ? saleDealerCode : dealerCode
    const params = { vinNo, fileName: "IN_" + vinNo, dealerCode }
    API.getInvioceView({ params }).then((res: any) => {
      pictureModal.pictureModalTitle = "IN_" + vinNo
      pictureModal.url = '/rvapi/basic/certificate/invoiceView'
      pictureModal.dealerCode = saleDealerCode
      if (res.docType.toUpperCase() === 'PDF') {
        pictureModal.pdf = URL.createObjectURL(base64toBlob(res.base64))
      } else {
        pictureModal.pdf = ''
        pictureModal.pictureModalImg = res.downloadUrl
      }
      pictureModal.pictureModalFlag = true
    })
  }

  const relationProofEvent = (vinNo: string, saleDealerCode = '') => {
    dealerCode = saleDealerCode ? saleDealerCode : dealerCode
    const params = { vinNo, fileName: "RP_" + vinNo, dealerCode }
    API.getRelationProofView({ params }).then((res: any) => {
      pictureModal.pictureModalTitle = "RP_" + vinNo
      pictureModal.url = '/rvapi/basic/certificate/relationProofView'
      pictureModal.dealerCode = saleDealerCode
      if (res.docType.toUpperCase() === 'PDF') {
        pictureModal.pdf = URL.createObjectURL(base64toBlob(res.base64))
      } else {
        pictureModal.pdf = ''
        pictureModal.pictureModalImg = res.downloadUrl
      }
      pictureModal.pictureModalFlag = true
    })
  }

  const closePictureModal = () => {
    pictureModal.pictureModalFlag = false
  }
  const pageChange = (page: number, pageSize: number) => {
    queryParams.current = page > 0 ? page : 1
    queryParams.size = pageSize;
    getTableData()
  };
  const sizeChange = (page: number, pageSize: number) => {
    queryParams.current = page > 0 ? page : 1
    queryParams.size = pageSize;
    getTableData()
  };
  const viewReasonEvent = (vinNo: string) => {
    const params = { vinNo, dealerCode };
    API.getFailReasonData({ params }).then((res) => {
      failData.value = [];
      res.forEach((item, index) => {
        failData.value.push({
          id: index,
          comments: item.comments,
          replyAt: item.replyAt,
        })
      })
      showFailFlag.value = true;
    })
  }
  const handleClick = (event: MouseEvent) => {
    isOpenPanle.value = false;
    activeKey.value = ""
    event.stopPropagation();
  }
  const handleActiveKey = () => {
    if (activeKey.value.indexOf("3") !== -1) {
      showCollapsed.value = true;
    } else {
      showCollapsed.value = false;
    }
  }

  const getAllBrands = async () => {
    const buBrandsList = await API.findBrandByBu(store.state.user?.buList.map((item: { id: number }) => item.id) ?? []) as any[]
    const brands = new Map();
    for (const buBrands of buBrandsList) {
      for (const brand of buBrands.brandList) {
        if (!brands.has(brand.id)) {
          brands.set(brand.id, brand);
        }
      }
    }
    return Array.from(brands.values()).map((item: { brandNameEn: string }) => {
      return item.brandNameEn;
    });
  }

  const handleExportResult = async () => {
    await waitBrandDataLoaded
    const params = {
      url: `/rvapi/basic/apply/dealer/resultExport`,
      method: 'post',
      params: {
        ...queryParams,
        brandList: queryParams.brand ? [] : allBrands
      },
      fileName: `${+new Date()}.xlsx`,
    };
    downloadFile(params);
  }

  getAllBrands().then((brands) => {
    allBrands = brands
    waitBrandDataLoaded.toRun?.()
  })

  return {
    getTableData,
    resetEvent,
    mulSearchEvent,
    downloadImg,
    pageChange,
    sizeChange,
    licenseEvent,
    invoiceEvent,
    relationProofEvent,
    viewReasonEvent,
    handleClick,
    handleActiveKey,
    overviewSerachEvent,
    getOverview,
    dateChangeEvent,
    closePictureModal,
    columns,
    failData,
    queryParams,
    overview,
    isOpenPanle,
    isOpen,
    tableData,
    total,
    pictureModal,
    activeKey,
    showFailFlag,
    showCollapsed,
    batchQuery,
    approvalStatusArray,
    rebateFlagArray,
    selectData,
    state,
    handleExportResult
  }
}
export default useDealerOverview
